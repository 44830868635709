import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const eventos = find("[js-page='eventos']")
	if (!eventos) return



	
	const swiperServicos = new Swiper(".swiperServicos", {
		slidesPerView: "auto",
		spaceBetween: 30,
		// autoplay: {
		// 	delay: 3000,
		// },
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		navigation: {
			nextEl: ".swiperServicos .swiper-button-next",
			prevEl: ".swiperServicos .swiper-button-prev",
		},
	});

// 	document.addEventListener('DOMContentLoaded', function() {
// 		const swiperSlides = document.querySelectorAll('.swiper-slide');

// 		swiperSlides.forEach(slide => {
// 				const card = slide.querySelector('.card-servicos');
// 				const textos = slide.querySelector('.textos-servico');
// 				const iconMais = slide.querySelector('.icon-mais');
// 				const img = slide.querySelector('.img')

// 				iconMais.addEventListener('click', function(event) {
// 						event.stopPropagation(); // Previne que o evento se propague para o card
// 						card.classList.add('ativo');
// 						textos.classList.add('ativo');
// 						iconMais.classList.add('ativo');
// 						img.classList.add('ativo');
// 				});

// 				card.addEventListener('click', function() {
// 						card.classList.remove('ativo');
// 						textos.classList.remove('ativo');
// 						iconMais.classList.remove('ativo');
// 						img.classList.remove('ativo');

// 				});
// 		});
// });
document.addEventListener('DOMContentLoaded', function() {
	const swiperSlides = document.querySelectorAll('.swiper-slide');

	swiperSlides.forEach(slide => {
			const card = slide.querySelector('.card-servicos');
			const textos = slide.querySelector('.textos-servico');
			const iconMais = slide.querySelector('.icon-mais');
			const img = slide.querySelector('.img');

			iconMais.addEventListener('click', function(event) {
					event.stopPropagation(); // Previne que o evento se propague para o card
					slide.classList.add('ativo');
					card.classList.add('ativo');
					textos.classList.add('ativo');
					iconMais.classList.add('ativo');
					img.classList.add('ativo');
			});

			card.addEventListener('click', function() {
					slide.classList.remove('ativo');
					card.classList.remove('ativo');
					textos.classList.remove('ativo');
					iconMais.classList.remove('ativo');
					img.classList.remove('ativo');
			});
	});
});
	
}

