import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const oTeatro = find("[js-page='o-teatro']")
	if (!oTeatro) return

	document.addEventListener("DOMContentLoaded", function() {
		// Seleciona todas as imagens clicáveis
		const imgs = document.querySelectorAll("#img-1, #img-2, #img-3, #img-4");
		
		imgs.forEach(img => {
			img.addEventListener("click", function() {
				// Remove a classe "ativo" de todas as imagens e detalhes
				imgs.forEach(i => i.classList.remove("ativo"));
				document.querySelectorAll(".detalhe").forEach(d => d.classList.remove("ativo"));

				// Remove a classe "ativo" do detalhe correspondente à imagem principal atual
				const imgPrincipal = document.querySelector(".img-principal img");
				const detalhePrincipalId = "detalhe-" + imgPrincipal.id.split("-")[1];
				document.getElementById(detalhePrincipalId).classList.remove("ativo");

				// Adiciona a classe "ativo" à imagem clicada
				this.classList.add("ativo");

				// Adiciona a classe "ativo" ao detalhe correspondente à imagem clicada
				const detalheId = "detalhe-" + this.id.split("-")[1];
				document.getElementById(detalheId).classList.add("ativo");

				// Troca de lugar as imagens
				const clickedImgSrc = this.src;
				const clickedImgAlt = this.alt;

				this.src = imgPrincipal.src;
				this.alt = imgPrincipal.alt;

				imgPrincipal.src = clickedImgSrc;
				imgPrincipal.alt = clickedImgAlt;

				// Remove e adiciona a classe "ativo" para reiniciar a animação
				imgPrincipal.classList.remove("ativo");
				void imgPrincipal.offsetWidth; // Reflow hack
				imgPrincipal.classList.add("ativo");
			});
		});
	});

	
	const swiperRestaurante = new Swiper(".swiperRestaurante", {
		slidesPerView: "auto",
		spaceBetween: 30,
		 autoplay: {
		 	delay: 3000,
		 },
		
	});
	const progressCircle = document.querySelector("#banner-o-teatro .autoplay-progress svg");
	const swiperBannerTeatro = new Swiper(".swiperBannerTeatro", {
		slidesPerView: "auto",
		spaceBetween: 30,
		 autoplay: {
		 	delay: 5000,
		 },
		 on: {
			 autoplayTimeLeft(s, time, progress) {
				 progressCircle.style.setProperty("--progress", 1 - progress);
				 // progressContent.textContent = `${Math.ceil(time / 1000)}s`;
			 }
		 }
		
	});



	
}

