import axios from 'axios'
import Swiper from "swiper"



export default function fetchGaleria(){
	
	const botaoVerMais = document.querySelector('#js-vermais')
	const swiperPortfolio = document.querySelector('.swiper-portfolio')
	const swiperPortfolioWrapper = document.querySelector('.swiper-portfolio .swiper-wrapper')
	if(!swiperPortfolio)return
	const inicial = swiperPortfolio.dataset.page 
	const pesquisaBotao =  document.querySelector('#pesquisa-botao')
	const formPesquisar =  document.querySelector('#form-pesquisar')
	


	let offset = inicial*12
	function renderGaleria(response,buscar){

		
		if (buscar){
			swiperPortfolioWrapper.innerHTML = ""
			pesquisaBotao.disabled = false
			pesquisaBotao.classList.remove("disabled")
			if(!response.data.results.length){
				
				swiperPortfolioWrapper.innerHTML = `   <p  class="text-title-4 text-cor-text text-center">Nenhuma imagem encontrada.</p>  `
				swiperPortfolioWrapper.style.display = "flex";
    		swiperPortfolioWrapper.style.justifyContent = "center";
				return
			} else {
				swiperPortfolioWrapper.style.display = "block";
			}
		}
		if(!response.data.results.length){
			botaoVerMais.style.display="none"
		}
		if(response.data.next){
			botaoVerMais.style.display="block"
		}
		console.log(response)
		offset +=12
		 const retornoHtml = response.data.results.map((item) => {
			return `
				<div class="swiper-slide">
					<div class="img-wrapper" >
						
						<a class="relative z-[10]" data-fancybox="gallery" href="${item.imagem}">
							
							<img src="${item.imagem}" alt="">
							<div class="absolute top-0 px-[16px] py-[16px] tag">
								<span class="text-caps-3 text-cor-4">${item.tag}</span>
							</div>
							<div class="px-[16px] py-[16px] absolute bottom-0 flex justify-between items-center w-full titulo-img">
								<p class="text-cor-4">${item.titulo}</p>
								<svg class="w-[40px] h-[40px]">
									<use xlink:href="#lupa-img"></use>
								</svg>
							</div>
						</a>
						
					</div>
				</div>
			`
		}).join("");
		swiperPortfolioWrapper.insertAdjacentHTML("beforeend",retornoHtml)  
		
		const mobile = window.matchMedia("(max-width:1024px)")
		if(mobile.matches){
			console.log(mobile)
			 new Swiper(".swiper-portfolio", {
				spaceBetween: 16,
				slidesPerView: 1.2,
				breakpoints: {
					640: {
						slidesPerView: 2.2,
						spaceBetween: 32,
					},
					768: {
						slidesPerView: 3.2,
						spaceBetween: 32,
					},
					1024: {
						slidesPerView: 5,
						spaceBetween: 32,
					},
				},
			});
		}
	}

	function getGaleria(url,buscar){

		axios.get(url,)
					.then((response) => renderGaleria(response,buscar))
					.catch((response) => console.log(response));
		
	}
	botaoVerMais.addEventListener('click', function(){
		const inputTexto =  document.querySelector('#input-texto')
		const url = `/api/galeria/?q=${inputTexto.value}&limit=10&offset=${offset}`
		getGaleria(url)
					
	});
	formPesquisar.addEventListener("submit", function(e) {
		
		pesquisaBotao.disabled = true
		pesquisaBotao.classList.add("disabled")
		e.preventDefault()
		const inputTexto =  document.querySelector('#input-texto')
		offset = 0
		const url = `/api/galeria/?q=${inputTexto.value}&offset=${offset}`
		getGaleria(url,true)
	})
}