import axios from 'axios'
import showToastify from '../utils/toastify';
import "toastify-js/src/toastify.css"
import { find } from 'utils/dom'




export default function  newsletterForm(){


    const contatoFormSubmit = document.querySelector('#newsletter-form')
    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
        event.preventDefault();
        disabledBtn()
         const form_data = {
              nome: document.querySelector('#newsletter-form input[name=nome]').value,
             email: document.querySelector('#newsletter-form input[name=email]').value,
            //  telefone: document.querySelector('#form-contato input[name=telefone]').value,
            //  mensagem: document.querySelector('#form-contato textarea[name=mensagem]').value,
            //  arquivo: document.querySelector('#form-contato input[name=arquivo]').files

         }

        // const formData = new FormData();
        
        // formData.append('email', document.querySelector('#newsletter-form input[name=email]').value);
        
       
        const url = "/newsletter/"
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector("#newsletter-form button[type=submit]")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add("disabled")
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove("disabled")
    }
   
}


