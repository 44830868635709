export default function () {
	document.addEventListener('DOMContentLoaded', () => {
			// Seleciona todos os itens com a classe 'js-event'
			const jsEventItems = document.querySelectorAll('.js-event');

			jsEventItems.forEach((item) => {
					// Seleciona o primeiro link <a> dentro do item e define width: fit-content
					const firstLink = item.querySelector('a.a-interno');
					if (firstLink) {
							firstLink.style.width = 'fit-content';

							// Impede a navegação para o primeiro link
							firstLink.addEventListener('click', (event) => {
									event.preventDefault(); // Impede a navegação
									// Alterna a classe 'ativar' no link <a>, no menu interno e na seta
									firstLink.classList.toggle('ativar');
									const menuInterno = item.querySelector('.menu-interno');
									const setaMenu = item.querySelector('.seta-menu');
									if (menuInterno) {
											menuInterno.classList.toggle('ativar');
									}
									if (setaMenu) {
											setaMenu.classList.toggle('ativar');
									}
									// Impede que o clique se propague para o item
									event.stopPropagation();
							});
					}

					// Adiciona um evento de clique ao menu interno para impedir a propagação
					const menuInterno = item.querySelector('.menu-interno');
					if (menuInterno) {
							menuInterno.addEventListener('click', (event) => {
									event.stopPropagation();
							});
					}

					// Adiciona um evento de clique aos outros links <a> dentro do menu
					const otherLinks = item.querySelectorAll('ul.menu-interno a.a-interno');
					otherLinks.forEach((link) => {
							link.addEventListener('click', (event) => {
									// Permite navegação normal para outros links
									// Nenhuma chamada para event.preventDefault() aqui
							});
					});
			});
	});
}
