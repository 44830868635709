import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageEventos from './pages/eventos.js'
import PageEspetaculo from './pages/espetaculo.js'
import PageOTeatro from './pages/o-teatro.js'
import PageProgramacao from './pages/programacao.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import menu from "./modules/menu";
import animaAoScroll2 from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";

import newsletter from "./modules/newsletter"
import contato from "./modules/contato"

import editor from "./modules/editor"

import { animaAoScroll } from "./animation/gsapanimations.js";
import animarTexto from "./animation/gsap.js";


import fetchGaleria from "./modules/fetchGaleria"


// import SvgUse from "./utils/SvgUse";
import instagramApi from './modules/instagramApi';
import instagramCodigoBase from './modules/instagramBase'
// Init
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll2()
fetchGaleria()
menu()

// Pages
PageHome()
PageEventos()
PageEspetaculo()
PageOTeatro()
PageLogin()?.init()
PageProgramacao()
// utils logs
logs()
editor()

newsletter()
contato()


animaAoScroll();


// document.addEventListener('DOMContentLoaded', (event) => {
// 	// Verifica se o elemento <main> tem o ID 'home'
// 	const mainElement = document.querySelector('main#Home');
// 	const mainElementTeatro = document.querySelector('main#o-teatro');

// 	if (mainElement,mainElementTeatro) {
// 			const menu = document.getElementById('header-mobile');
// 			menu.classList.remove('solid');

// 			window.addEventListener('scroll', function() {
// 					const menu = document.getElementById('header-mobile');
// 					if (window.scrollY > 50) { // Ajuste o valor conforme necessário
// 							menu.classList.remove('transparent');
// 							menu.classList.add('solid');
// 					} else {
// 							menu.classList.remove('solid');
// 							menu.classList.add('transparent');
// 					}
// 			});
// 	}
// });


// document.addEventListener('DOMContentLoaded', (event) => {
// 	// Seleciona todos os elementos com a classe 'menu-externo'
// 	const menuExternoItems = document.querySelectorAll('.menu-externo');
	


// 	// Adiciona um evento de clique a cada item do menu externo
// 	menuExternoItems.forEach(item => {
// 			item.addEventListener('click', (event) => {
// 					// Impede a ação padrão do evento
// 					event.preventDefault();

// 					// Seleciona o link <a> e o menu interno dentro do item clicado
// 					const link = item.querySelector('a.a-interno');
// 					const menuInterno = item.querySelector('.menu-interno');

// 					if (link && menuInterno) {
// 							// Alterna a classe 'ativar' no link <a> e no menu interno
// 							link.classList.toggle('ativar');
// 							menuInterno.classList.toggle('ativar');
// 					} 
// 			});
// 	});
// });





document.addEventListener("DOMContentLoaded", function() {

	const customSelect = document.getElementById("filtro-banda");
	if(!customSelect)return
	const selected = customSelect.querySelector(".select-selected");
	const items = customSelect.querySelector(".select-items");

	selected.addEventListener("click", function() {
			this.nextElementSibling.classList.toggle("select-hide");
			this.classList.toggle("select-arrow-active");
	});

	items.addEventListener("click", function(e) {
			if (e.target.tagName === "DIV") {
					selected.textContent = e.target.textContent;
					selected.dataset.value = e.target.dataset.value;
					items.classList.add("select-hide");
					selected.classList.remove("select-arrow-active");
			}
	});

	document.addEventListener("click", function(e) {
			if (!customSelect.contains(e.target)) {
					items.classList.add("select-hide");
					selected.classList.remove("select-arrow-active");
			}
	});
});

const datasSection = document.querySelector("#filtro-eventos")
if(datasSection){
	const datas = datasSection.dataset.datas


	const arrayDatas = datas.split(", ")
	console.log(arrayDatas)
const calendar = new HelloWeek({
	selector: '.hello-week',
	lang: 'pt',
	langFolder:  `https://cdn.jsdelivr.net/npm/hello-week@2.10.3/dist/langs/pt.json` ,
	format: 'DD/MM/YYYY',
	 daysHighlight: arrayDatas.map(evento => ({days:[evento]})),
	 
	
	// daysHighlight: [
	// 		{
	// 				days: ['2024-08-01'],
	// 				backgroundColor: '#f08080',
	// 				color: '#000',
	// 				title: 'Dad Birthday',
	// 		},
	// 		{
	// 				days: ['2024-08-20'],
	// 				backgroundColor: '#f08080',
	// 				color: '#000',
	// 				title: 'Mom Birthday',
	// 		},
	// 		{
	// 				days: [
	// 						['2024-08-25', '2024-08-27'],
	// 						['2024-09-24', '2024-09-29'],
	// 				],
	// 				backgroundColor: '#fff',
	// 				color: '#000',
	// 				title: 'Summer Holidays',
	// 		},
	// ],

    daysShort: ["D",
    "S",
    "T",
    "Q",
    "Q",
    "S",
    "S"],
    
	
		daysShort:true,
	weekShort: true,
	monthShort: false,
	multiplePick: false,
	defaultDate: null,
	todayHighlight: false,
	disablePastDays: false,
	disabledDaysOfWeek: null,
	disableDates: null,
	weekStart: 0, // week start on Sunday
	rtl: false,
	locked: false,
	minDate: null,
	maxDate: null,
	nav: ['◀', '▶'],
	onLoad: () => {  

			// eventosContainer.innerHTML = eventos.map( evento => gerarCardEvento(evento)).join('');
	},
	onChange: () => { /** callback function */ },
	onSelect: () => {

			 const dayTimestamp = calendar.getDaySelected();

			 


			 const dayClear = new Date(dayTimestamp);
			 const day = dayClear.getUTCDate();
			 const month = dayClear.getUTCMonth() + 1;
			 const year =  dayClear.getFullYear();
			 const correctData = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
				console.log(correctData)
				let newUrl = new URL(window.location.href);
            // newUrl.pathname = 'eventos/';
            newUrl.searchParams.set('data', correctData);
            window.history.replaceState({}, '', newUrl);
						window.location.href = newUrl
			//  const eventFiltered = eventos.filter( evento => evento.data ==  correctData)
			//  eventosContainer.innerHTML = eventFiltered.length ? eventFiltered.map( evento => gerarCardEvento(evento)).join('') : '<p>Não há eventos na data selecionada</p>'

			//  document.querySelector('#eventos').scrollIntoView({behavior: "smooth"})

		},
	onClear: () => { /** callback function */ }
});
}



document.addEventListener('DOMContentLoaded', function() {
	const headerMobile = document.getElementById('header-mobile');
	const path = window.location.pathname;

	// Verifica se a URL atual é "home" ou "o-teatro"
	// if (path === '/' || path === '/home' || path === '/o-teatro/' || path === '/eventos/') {
		// Escuta o evento de scroll apenas nas páginas especificadas
		window.addEventListener('scroll', function() {
			if (window.scrollY > 0) {
				headerMobile.classList.add('ativo');
			} else {
				headerMobile.classList.remove('ativo');
			}
		});
	// } else {
	// 	// Em outras páginas, a classe 'ativo' já está presente
	// 	headerMobile.classList.add('ativo');
	// }
});


document.addEventListener("DOMContentLoaded", function() {
	setTimeout(() => {
		const hash = window.location.hash;
		if (hash) {
				// Seleciona o elemento correspondente ao hash
				const element = document.querySelector(hash);
				if (element) {
						// Rola suavemente até o elemento
						element.scrollIntoView({ behavior: "smooth" });
				}
		}
	}, 300);
	// Verifica se a URL contém um hash
	
});


// const swiperTeatro = new Swiper(".swiperTeatro", {
// 	spaceBetween: 30,
// 	centeredSlides: true,
// 	autoplay: {
// 		delay: 2500,
// 		disableOnInteraction: false
// 	},
	
	
// });


document.addEventListener("DOMContentLoaded", () =>{
  document.body.classList.add("dcl")
  if ( !window.matchMedia('(max-width: 768px)').matches ){
    animarTexto();
  }
})



instagramCodigoBase()


instagramApi()
