import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return



	/** Swiper Inovação | Comunicação | Crescimento */
	const SwiperInovacaoComunicaoCrescimento = new Swiper(".Swiper-Inovacao-Comunicacao-Crescimento", {
		slidesPerView: 5,
		spaceBetween: 0,

	})

	const SwiperInovacao = new Swiper(".SwiperInovacao", {
		slidesPerView: 2.5,
		spaceBetween: 0,
		direction: "vertical",
		centeredSlides: true,
		speed: 700,
		loop: true,
		autoplay: {
			delay: 3000,
		}

	});

	const SwiperComunicacao = new Swiper(".SwiperComunicacao", {
		slidesPerView: "auto",
		spaceBetween: 0,
		direction: "vertical",
		centeredSlides: true,
		speed: 700,
		loop: true,
		autoplay: {
			delay: 3000,
		}

	});

	const swiperBannerHome = new Swiper(".swiperBannerHome", {
		slidesPerView: 1,
    spaceBetween: 16,
		autoHeight: true,
      autoplay:false,
			// speed: 400,
      //  autoplay: {
      //      delay: 5000,
      //      disableOnInteraction: true,
      //  },
		pagination: {
			el: ".swiperBannerHome .swiper-pagination",
			clickable: true,
		},
    
    navigation: {
        nextEl: ".swiperBannerHome .swiper-button-direita",
        prevEl: ".swiperBannerHome .swiper-button-esquerda",
      },
	});

	const swiperEventos = new Swiper(".swiperEventos", {
		slidesPerView: "auto",
		spaceBetween: 20,
		speed: 700,	
		autoplay: {
			delay: 2000,
			disableOnInteraction: true,
		},
		// breakpoints: {
		// 	540: {
		// 		slidesPerView: 1.6,
		// 		spaceBetween: 20,
		// 	},
		// 	660: {
		// 		slidesPerView: 2.2,
		// 		spaceBetween: 40,
		// 	},
		// 	768: {
		// 		slidesPerView: 3,
		// 		spaceBetween: 40,
		// 	},
		// 	1024: {
		// 		slidesPerView: 3.2,
		// 		spaceBetween: 50,
		// 	},
		// 	1224: {
		// 		slidesPerView: 4.5,
		// 		spaceBetween: 50,
		// 	},
		// 	1724: {
		// 		slidesPerView: 5.5,
		// 		spaceBetween: 50,
		// 	},

		// },
	});


	const breakpoint = window.matchMedia("(min-width:1024px)");
  // keep track of swiper instances to destroy later
  let swiperPortfolio;
  let swiperNumerosSobre;

  const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      if (swiperPortfolio !== undefined) swiperPortfolio.destroy(true, true);
      if (swiperNumerosSobre !== undefined) {
        swiperNumerosSobre.destroy(true, true);
      }
      // or/and do nothing
      return;
      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      return enableSwiper();
    }
  };

	const enableSwiper = function () {
    swiperPortfolio = new Swiper(".swiper-portfolio", {
      spaceBetween: 16,
      slidesPerView: 1.2,
      breakpoints: {
        640: {
          slidesPerView: 2.2,
          spaceBetween: 32,
        },
        768: {
          slidesPerView: 3.2,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 32,
        },
      },
    });
    swiperNumerosSobre = new Swiper(".swiper-numeros-sobre", {
      spaceBetween: 16,
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
      },
    });
  };
	const progressCircle = document.querySelector("#sobre-home .autoplay-progress svg");
  // const progressContent = document.querySelector("#sobre-home  .autoplay-progress span");
	const swiperSobre = new Swiper(".swiperSobre", {
			
			grabCursor: true,
			effect: "creative",
			speed: 700,
			mousewheel:{enable:true},
			autoplay: {
					delay: 4000,
					disableOnInteraction: false,
			},
			creativeEffect: {
					prev: {
							translate: [0, '-100%', 0],
							scale: 1,
					},
					next: {
							shadow: true,
							translate: [0, '20%', -100],
							scale: 0.8,
					},
			},
			pagination: {
					el: '.swiper-pagination-sobre',
					clickable: true,
					type: 'bullets',
			},
			on: {
        autoplayTimeLeft(s, time, progress) {
          progressCircle.style.setProperty("--progress", 1 - progress);
          // progressContent.textContent = `${Math.ceil(time / 1000)}s`;
        }
      }
			
	});

	document.addEventListener('DOMContentLoaded', function() {
		Fancybox.bind('[data-fancybox]', {
			Toolbar: {
				display: [
					"zoom",
					"slideshow",
					"fullscreen",
					"download",
					"thumbs",
					"close"
				]
			},
				Thumbs: {
						autoStart: true,
				},
				Toolbar: {
						display: ['zoom', 'close']
				},
				defaultType: "image",
				width: "90%",
				height: "90%",
				fitToView: true,
				aspectRatio: true, // Mantém a proporção da imagem
				AnimationEffect: "zoom-in-out"
		});
	});
	// keep an eye on viewport size changes
  breakpoint.addListener(breakpointChecker);
  // kickstart
  breakpointChecker();
}

