import axios from 'axios'
import showToastify from '../utils/toastify';
import "toastify-js/src/toastify.css"
import { find } from 'utils/dom'



export default function  contatoForm(){


	const contatoFormSubmit = document.querySelector('#contato-form')
	contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
			event.preventDefault();
			disabledBtn()
			 const form_data = {
						nome: document.querySelector('#contato-form input[name=nome]').value,
					 email: document.querySelector('#contato-form input[name=email]').value,
					  // assunto: document.querySelector('#contato-form input[name=assunto]').value,
					 mensagem: document.querySelector('#contato-form textarea[name=mensagem]').value,
					//  arquivo: document.querySelector('#form-contato input[name=arquivo]').files

			 }

			// const formData = new FormData();
			
			// formData.append('email', document.querySelector('#contato-form input[name=email]').value);
			
		 
			const url = "/fale-conosco/"
			axios.post(url, form_data)
					.then((response) => showMessage(response))
					.catch((response) => showMessage(response));
	});

	function showMessage(response) {
			const message = response.data ? response.data.message : response.message
			showToastify(message)
			contatoFormSubmit.reset()
			setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector("#contato-form button[type=submit]")

	function disabledBtn() {
			btnSubmit.disabled = true
			btnSubmit.classList.add("disabled")
	}

	function enabledBtn() {
			btnSubmit.disabled = false
			btnSubmit.classList.remove("disabled")
	}
 
}