import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const espetaculo = find("[js-page='espetaculo']")
	if (!espetaculo) return


	const swiperArtistas = new Swiper(".swiperArtistas", {
		slidesPerView: 'auto',
		spaceBetween: 20,
		speed: 700,
		autoplay: {
			delay: 2000,
			disableOnInteraction: true,
		},
		// breakpoints: {
		// 	540: {
		// 		slidesPerView: 1.8,
		// 		spaceBetween: 20,
		// 	},
		// 	660: {
		// 		slidesPerView: 2.2,
		// 		spaceBetween: 40,
		// 	},
		// 	768: {
		// 		slidesPerView: 3,
		// 		spaceBetween: 40,
		// 	},
		// 	1024: {
		// 		slidesPerView: 3.2,
		// 		spaceBetween: 50,
		// 	},
		// 	1224: {
		// 		slidesPerView: 4.5,
		// 		spaceBetween: 50,
		// 	},
		// 	1724: {
		// 		slidesPerView: 6.8,
		// 		spaceBetween: 50,
		// 	},

		// },
	});


	
}

